import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { get, ref } from 'firebase/database';
import { collection, getDocs } from 'firebase/firestore';
import { db, realtime } from '../firebase/firebase-setup';
import { getUserToken } from '../shared/helpers';
import { Stats, StatsTenant } from '../shared/models';
import { AppThunk } from './store';

interface StatsState {
  stats?: Stats;
  isActive: boolean;
}

const initialState: StatsState = {
  isActive: false,
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),
    setStats: (state, action: PayloadAction<Stats>) => {
      state.stats = action.payload;
    },
  },
});

export const getStats = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();

  const rtdb = await get(ref(realtime, `tenants`));
  const rtdbData = rtdb.val();

  const activityTenants = {};
  Object.entries(rtdbData).forEach(([tenantId, tenantData]) => {
    activityTenants[tenantId] = {};
    const inboxes = tenantData['inboxes'];
    if (inboxes) {
      Object.values(inboxes).forEach((inboxData) => {
        const userActivity = inboxData['user_activity'];
        if (userActivity) {
          Object.entries(userActivity).forEach(([userId, userData]) => {
            if (
              !activityTenants[tenantId][userId] ||
              Date.parse(activityTenants[tenantId][userId].last_active) <
                Date.parse(userData['last_active'])
            ) {
              activityTenants[tenantId][userId] = userData;
            }
          });
        }
      });
    }
  });

  const activityThreshold = 10 * 1000 * 60;

  const activityTotals = { total: 0, tenants: {} };
  const reduceFn = (previousValue: number, [k, currentValue]: any) => {
    const total: any = Object.values(currentValue).reduce(
      (prev: number, curr: any) =>
        Date.now() - Date.parse(curr.last_active) < activityThreshold ? prev + 1 : prev,
      0
    );
    activityTotals.tenants[k] = total;
    return previousValue + total;
  };

  activityTotals.total = Object.entries(activityTenants).reduce(reduceFn, 0);

  const res = await getDocs(collection(db, 'tenants'));
  let statsTenants: StatsTenant[] = res.docs.map((e) => {
    const data = e.data();
    const t: StatsTenant = {
      id: e.id,
      name: data.settings.name,
      domain: data.config.subdomain,
      automationPercent: null,
      activeUsers: activityTotals.tenants[e.id] ?? 0,
      inflow: null,
      open: null,
      workflowStats: null,
    };
    return t;
  });

  const d = new Date();
  const startOfDay = new Date(d.getFullYear(), d.getMonth(), d.getDate());

  const reqConfig = {
    params: { from_timestamp: startOfDay.getTime() },
    headers: { authorization: 'Bearer ' + b },
  };

  const { data: inflowData } = await axios.get(
    `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/analytics/inflow`,
    reqConfig
  );
  const { data: automationData } = await axios.get(
    `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/analytics/automation`,
    reqConfig
  );
  const { data: openDocsData } = await axios.get(
    `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/analytics/open`,
    reqConfig
  );

  const inflowTotal = inflowData.inflow.count;
  statsTenants = statsTenants.map((st) => {
    return { ...st, inflow: inflowData.inflow?.tenants[st.id]?.count ?? 0 };
  });

  const automationTotal = automationData.automation.percentage;
  statsTenants = statsTenants.map((st) => {
    return {
      ...st,
      automationPercent:
        automationData.automation?.tenants[st.id]?.percentage.toFixed(1) ?? 0,
    };
  });

  const openTotal = openDocsData.open.count;
  statsTenants = statsTenants.map((st) => {
    const inboxes = {};
    Object.entries(openDocsData.open?.tenants[st.id]?.inboxes ?? {}).forEach(
      ([inboxId, inboxData]: any) => {
        inboxes[inboxId] = {
          open: inboxData.count,
          workflowStats: inboxData.status_counts,
        };
      }
    );
    return {
      ...st,
      workflowStats: openDocsData.open?.tenants[st.id]?.status_counts ?? {},
      open: openDocsData.open?.tenants[st.id]?.count ?? 0,
      inboxes: inboxes,
    };
  });
  const workflowStatsTotal = statsTenants
    .map((st) => st.workflowStats)
    .reduce((prev, curr) => {
      return { ...prev, ...curr };
    });
  console.log(workflowStatsTotal);

  const statsData: Stats = {
    inflow: inflowTotal,
    open: openTotal,
    workflowStats: workflowStatsTotal,
    activeUsers: activityTotals.total,
    automationPercent: automationTotal,
    tenants: statsTenants,
  };
  console.log(statsTenants);

  dispatch(statsSlice.actions.setStats(statsData));
};
